// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #cf0061;
  --ion-color-primary-rgb: 207, 0, 97;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #b60055;
  --ion-color-primary-tint: #d41a71;

  --ion-color-secondary: #77003d;
  --ion-color-secondary-rgb: 119, 0, 61;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #690036;
  --ion-color-secondary-tint: #851a50;

  --ion-color-tertiary: #ea82af;
  --ion-color-tertiary-rgb: 234, 130, 175;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #ce729a;
  --ion-color-tertiary-tint: #ec8fb7;

  --ion-color-success: #1c7d52;
  --ion-color-success-rgb: 28, 125, 82;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #196e48;
  --ion-color-success-tint: #338a63;

  --ion-color-warning: #feb420;
  --ion-color-warning-rgb: 254, 180, 32;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e09e1c;
  --ion-color-warning-tint: #febc36;

  --ion-color-danger: #df4848;
  --ion-color-danger-rgb: 223, 72, 72;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c43f3f;
  --ion-color-danger-tint: #e25a5a;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #aaaaaa;
  --ion-color-medium-rgb: 170, 170, 170;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #969696;
  --ion-color-medium-tint: #b3b3b3;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  ion-modal,
  ion-popover,
  ion-card,
  ion-loading,
  ion-select-popover,
  ion-alert {
    border-radius: 5px;
    --border-radius: 5px;
    --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;

    --ion-text-color: #000000;
    --ion-text-color-rgb: 0, 0, 0;

    --ion-color-step-50: #f2f2f2;
    --ion-color-step-100: #e6e6e6;
    --ion-color-step-150: #d9d9d9;
    --ion-color-step-200: #cccccc;
    --ion-color-step-250: #bfbfbf;
    --ion-color-step-300: #b3b3b3;
    --ion-color-step-350: #a6a6a6;
    --ion-color-step-400: #999999;
    --ion-color-step-450: #8c8c8c;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #737373;
    --ion-color-step-600: #666666;
    --ion-color-step-650: #595959;
    --ion-color-step-700: #4d4d4d;
    --ion-color-step-750: #404040;
    --ion-color-step-800: #333333;
    --ion-color-step-850: #262626;
    --ion-color-step-900: #191919;
    --ion-color-step-950: #0d0d0d;
  }
/* 
  --ion-background-color: #fafafa;
  --ion-background-color-rgb: 250, 250, 250;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0; */

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;

  --ion-color-step-50: #eeeeee;
  --ion-color-step-100: #e1e1e1;
  --ion-color-step-150: #d5d5d5;
  --ion-color-step-200: #c8c8c8;
  --ion-color-step-250: #bcbcbc;
  --ion-color-step-300: #afafaf;
  --ion-color-step-350: #a3a3a3;
  --ion-color-step-400: #969696;
  --ion-color-step-450: #8a8a8a;
  --ion-color-step-500: #7d7d7d;
  --ion-color-step-550: #707070;
  --ion-color-step-600: #646464;
  --ion-color-step-650: #585858;
  --ion-color-step-700: #4b4b4b;
  --ion-color-step-750: #3f3f3f;
  --ion-color-step-800: #323232;
  --ion-color-step-850: #262626;
  --ion-color-step-900: #191919;
  --ion-color-step-950: #0d0d0d;
}

.ion-color-white {
  --ion-color-base: #ffffff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-base-contrast: #000000;
  --ion-color-base-contrast-rgb: 0, 0, 0;
  --ion-color-contrast: #000000 !important;
  --ion-color-contrast-rgb: 0, 0, 0 !important;
  --ion-color-base-shade: #ffffff;
  --ion-color-base-tint: #ffffff;
}
