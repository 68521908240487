/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import '@angular/material/theming';

@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

@include mat-core();
$app-primary: mat-palette($mat-red, 500);
$app-accent: mat-palette($mat-red, 300);
$app-success: mat-palette($mat-green);
$app-warn: mat-palette($mat-red);
$app-theme: mat-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography: mat-typography-config(),
    density: 0,
  )
);
$app-theme-dark: mat-dark-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);
@include angular-material-theme($app-theme);

@font-face {
  font-family: 'Google-Regular';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/product-sans/Product\ Sans\ Regular.ttf') format('truetype');
  // --ion-font-family: 'AppFont';
}

@font-face {
  font-family: 'Nunito-Regular';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/Nunito/static/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/Nunito-VariableFont_wght.ttf') format('truetype');
}

.Nunito-Regular {
  font-family: 'Nunito-Regular', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.Nunito {
  font-family: 'Nunito', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.parsererror {
  display: none !important;
}

/* @font-face {
  font-family: AppFont;
  --ion-font-family: 'AppFont';
  src: url('./assets/fonts/Nunito-VariableFont_wght.ttf') format('truetype');
} */

/* * {
  font-family: 'AppFont';
} */

.firebase-emulator-warning {
  display: none;
}

.subjectChange {
  border-radius: 4px; //same as mat menu
  width: 95%;
  background: white;
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  padding: 15px;
}

.sceneChangeWrapper {
  position: absolute;
  left: 0;
  top: 5px;
  width: 100%;
  justify-content: center;
  display: flex;
  z-index: 1000;
}

ion-header {
  background: var(--ion-color-background);
  color: var(--ion-text-color);

  .headerLogo {
    height: 20px;
  }

  ion-toolbar {
    --min-height: 35px;
  }
}

.title-text {
  text-align: start;
  font-weight: 600;
  font-size: 28px;
  text-overflow: ellipsis;
  margin: 0;
}

.title-text-s {
  text-align: start;
  font-weight: 500;
  font-size: 20px;
  text-overflow: ellipsis;
  margin: 0;
}

/* app-home,
app-history,
app-statistics,
app-options,
app-profile {
  margin-top: 57px !important; //header height
} */

/*
@media (prefers-color-scheme: light) {
  .bg {
    background-image: url(assets/images/bg.svg);
    background-size: cover;
  }
}
@media (prefers-color-scheme: dark) {
  .bg {
    background-image: url(assets/images/dark-bg.svg);
    background-size: cover;
  }
} */
.verticalCentering {
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: middle;
  align-items: center;
}

.justifyCenter {
  justify-content: center;
}

.centering {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.displayNone {
  display: none;
}

.white {
  color: var(--ion-color-white);
}

.light {
  color: var(--ion-color-light);
}

.alwaysLight {
  color: var(--ion-color-alwaysLight);
}

.alwaysDark {
  color: var(--ion-color-alwaysDark);
}

.flex {
  display: flex;
}

.flexCenter {
  display: flex;
  justify-content: center;
  text-align: center;
}

.flexVerticalCenter {
  display: flex;
  align-items: center;
}

.flexCol {
  flex-direction: column;
}

.flexCenterCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.nowrap {
  display: inline-flex;
  flex-wrap: nowrap;
}

.spaceBetween {
  justify-content: space-between;
}

.marginTop30 {
  margin-top: 30px;
}

.marginTop25 {
  margin-top: 25px;
}

.marginTop20 {
  margin-top: 20px;
}

.marginTop15 {
  margin-top: 15px;
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop5 {
  margin-top: 5px;
}

.backButton {
  margin: 0 3px 0 14px;
  --padding-end: 0;
  --padding-start: 0;
}

.modalTitle {
  font-size: 16px;
  padding: 0 60px;
}

.disableValidationBorders {
  --border-color: 0;
  box-shadow: none !important;
  --highlight-color: 0px solid transparent;
  --highlight-color-valid: 0px solid transparent;
  --highlight-color-invalid: 0px solid transparent;
  --highlight-color-focused: 0px solid transparent;
}

.disableValidationBordersExceptInvalid {
  --border-color: 0;
  --highlight-color: 0px solid transparent;
  --highlight-color-valid: 0px solid transparent;
  --highlight-color-focused: 0px solid transparent;
}

ion-modal::part(backdrop) {
  background: var(--ion-backdrop-color, #000);
  opacity: 1;
}

ion-modal ion-header ion-toolbar {
  --background: var(--ion-color-primary);
  --color: white;
  padding-top: 0px !important; //ios-en a safe-area miatt a toolbar-on lesz egy 59px padding-top, ami nem kell modalban.
}

ion-modal .ion-page {
  //display: block;
  overflow: auto;
}

.questModal {
  --height: 100%;
  --width: 100%;
  --padding: 0px;
  // --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.iconInButton {
  height: 20px;
  width: 20px;
  //margin-bottom: 0px;
}

ion-button.button-disabled {
  opacity: 1 !important;
  --ion-color-base: var(--ion-color-tertiary) !important;
  --ion-color-base-rgb: var(--ion-color-tertiary-rgb) !important;
  --ion-color-contrast: var(--ion-color-tertiary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-tertiary-shade) !important;
  --ion-color-tint: var(--ion-color-tertiary-tint) !important;
}

.defaultModal {
  --height: 22em;
  --width: 22em;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  border-radius: 0 !important;
  padding: 10px;
}

.buttonXS {
  width: auto;
  height: auto;
  min-height: 20px;
  font-size: 12px;
  margin: 0;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-end: 4px;
  --padding-start: 4px;
  white-space: normal;
  text-transform: uppercase;
  border-radius: 5px;
  --border-radius: 5px;
}

.buttonM {
  width: calc(100% - 5px);
  min-height: 35px;
  font-size: 15px;
  margin: 0;
  word-break: break-all;
  --padding-top: 3px;
  --padding-bottom: 3px;
  --padding-end: 4px;
  --padding-start: 4px;
  white-space: normal;
  text-transform: uppercase;
  border-radius: 5px !important;
  --border-radius: 5px !important;
}

.buttonS {
  width: calc(100% - 5px);
  min-height: 25px !important;
  font-size: 13px;
  margin: 0;
  word-break: break-all;
  --padding-top: 3px;
  --padding-bottom: 3px;
  --padding-end: 4px;
  --padding-start: 4px;
  white-space: normal;
  text-transform: uppercase;
  border-radius: 5px !important;
  --border-radius: 5px !important;
  --border-width: 1px;
}

.buttonAutoWidth {
  width: auto;
}

.button100Width {
  width: 100% !important;
}

.invalidInput {
  border: 1px solid var(--ion-color-danger);
}

ion-toast.custom-toast::part(message) {
  font-size: 16px;
}

ion-toast.version-toast::part(message) {
  font-size: 16px;
}

ion-toast.version-toast::part(button) {
  margin-right: 16px;
}

.biggerBottomMargin {
  margin-bottom: 17px !important;
}

.hideButton {
  opacity: 0 !important;
  pointer-events: none !important;
}

/* .neptunModalVisible {
  align-items: flex-end;
  padding-bottom: 10px;
  display: flex;
} */

.badge-modal {
  --width: 19em;
  --max-height: auto;
  --height: auto;
}

.challenge-modal {
  --width: 300px;
  --height: 330px;
}

.image-modal {
  --width: auto;
  --max-height: auto;
  --height: auto;
  --background: transparent;
  --box-shadow: none;
}

.neptun-modal {
  border-radius: 0px !important;

  &.medium {
    --width: 220px;
    --height: 260px;
  }

  &.small {
    --width: 210px;
    --height: 210px;
  }

  ion-input {
    min-height: auto !important;
  }

  .neptunItem {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 6px 16px;
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;

    ion-col {
      padding: 0;
      padding-bottom: 4px;
    }
  }

  .modalFooter {
    justify-content: flex-end;
    display: flex;
    padding: 0px 10px;
  }

  .customButton {
    width: auto;
    min-width: 40px;
    padding-bottom: 5px;
    padding-right: 5px;
    font-weight: 500;
  }
}

.disabledButton {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.enabledButton {
  opacity: 1 !important;
  pointer-events: initial !important;
}

.primary {
  color: var(--ion-color-primary);
}

.warning {
  color: var(--ion-color-warning);
}

.success {
  color: var(--ion-color-success);
}

.secondary {
  color: var(--ion-color-secondary);
}

.danger {
  color: var(--ion-color-danger) !important;
}

.light {
  color: var(--ion-color-light);
}

.dark {
  color: var(--ion-color-dark) !important;
}

.delayedQuizLoading {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  width: 100%;
  height: 97.5%;
  bottom: 0;
  padding: 0;
  margin: 0;
  left: 0;
}

.noInternetBackdrop {
  opacity: 0.4;
  background: var(--ion-color-dark);
}

.confirmAlert .cancel-button {
  color: var(--ion-color-dark);
}

.alert-center .alert-wrapper {
  text-align: justify;
  justify-content: center;
  border-radius: 5px;
}

.alert-center .alert-head {
  text-align: center;
}

.alert-message.sc-ion-alert-ios {
  text-align: justify;
}

.alert-header-center .alert-head {
  text-align: center;
}

.forgot-password-alert {
  --max-width: 40vh;

  .alert-head {
    text-align: center;
  }
}

.preCodeTitleMargin {
  pre {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

.preCodeSpanMargin {
  pre {
    margin: 10px -10px 10px 0px;
  }
}

.preCodeExplanation,
.preCodeHint {
  pre {
    margin-top: 4px;
    margin-bottom: 4px;
    text-align: start !important;
  }
}

.preCode {
  width: 100%;

  pre {
    border-radius: 5px;
    background: rgb(245, 245, 245);
    color: var(--ion-color-dark);
    padding: 10px 7px;
    white-space: break-spaces;
    font-size: 0px;
  }

  code {
    line-height: 1.5;
    font-size: 14px;
  }
}

@mixin center-content {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: var(--ion-background-color);
}

.center-content {
  @include center-content();
}

.contentPadding {
  --padding-end: 4vw;
  --padding-start: 4vw;
  padding-left: 4vw;
  padding-right: 4vw;

  .fab {
    padding-right: 4vw;
    right: 0;
    bottom: 20px;
  }

  .fab-left {
    padding-left: 4vw;
    left: 0;
    bottom: 20px;
  }
}

.hintModal {
  border-radius: 0px !important;
  align-items: flex-end;
  --height: auto;
  --width: 100vw;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 140px;
  --box-shadow: 2px 2px 4px 0px #898989, -4px 0px 4px -5px #898989, 0px -1px 4px 0px #898989;
  --backdrop-opacity: 0.5;

  &::part(content) {
    border-radius: 5px !important;
  }
}

.noQuest {
  max-height: 40px;
  border-radius: 20px;
  text-align: justify;
  margin: 10px 15px;
  display: flex;
  padding: 10px;
}

.disabled {
  opacity: 0.7 !important;
  pointer-events: none !important;
}

.disableBorder {
  border: none !important;
}

.noPE {
  pointer-events: none !important;
}

.willChangeContents {
  will-change: contents;
}

//ez csak android-ra működik
@supports not (-webkit-touch-callout: none) {
  ion-content {
    --keyboard-offset: 0 !important;
  }
}

ng-otp-input {
  input:focus-visible {
    outline: 1px solid var(--ion-color-medium) !important;
  }

  .ng-otp-input-wrapper .wrapper {
    display: flex;
    justify-content: center;
    padding: 0px 5px;
  }

  .ng-otp-input-wrapper .otp-input:not(:last-child) {
    margin-right: 4px !important;
  }

  .ng-otp-input-wrapper .otp-input {
    background: var(--ion-color-white);
    color: var(--ion-color-dark);
    //border: 1px solid var(--ion-color-medium),
    border-radius: 5px;
    //  outline: none;
    margin-top: 2px;
    margin-bottom: 2px;
    height: 40px;
    width: 40px;
    font-size: 20px;
    outline: 1px solid var(--ion-color-light);
    // margin-right: 15px !important;
  }
}

.modal-50 {
  --height: 50%;
  --width: 95%;
  --padding: 0px;
  // align-items: flex-start;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

//@media (hover: hover) and (pointer: fine) {

@media screen and (max-width: 350px) {
  .contentPadding {
    --padding-end: 2vw;
    --padding-start: 2vw;
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .fab {
    padding-right: 2vw !important;
  }

  .fab-left {
    padding-left: 2vw !important;
  }
}

@media screen and (min-width: 800px) {
  .contentPadding {
    --padding-end: 10vw;
    --padding-start: 10vw;
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .fab {
    padding-right: 10vw !important;
  }

  .fab-left {
    padding-left: 10vw !important;
  }

  .hintModal {
    padding-left: 10vw;
    padding-right: 10vw;
    padding-bottom: 140px;
  }

  .subjectChange {
    margin-right: 2.5vw;
    margin-left: 2.5vw;
    width: auto;
  }

  .sceneChangeWrapper {
    justify-content: end;
  }
}

@media screen and (min-width: 1000px) {
  .contentPadding {
    --padding-end: 20vw;
    --padding-start: 20vw;
    padding-left: 20vw;
    padding-right: 20vw;
  }

  .fab {
    padding-right: 20vw !important;
  }

  .fab-left {
    padding-left: 20vw !important;
  }

  .hintModal {
    padding-left: 20vw;
    padding-right: 20vw;
    padding-bottom: 140px;
  }
}

@media screen and (min-width: 1250px) {
  .contentPadding {
    --padding-end: 30vw;
    --padding-start: 30vw;
    padding-left: 30vw;
    padding-right: 30vw;
  }

  .fab {
    padding-right: 30vw !important;
  }

  .fab-left {
    padding-left: 30vw !important;
  }

  .hintModal {
    padding-left: 30vw;
    padding-right: 30vw;
    padding-bottom: 140px;
  }
}

@media screen and (min-width: 1700px) {
  .contentPadding {
    --padding-end: 35vw;
    --padding-start: 35vw;
    padding-left: 35vw;
    padding-right: 35vw;
  }

  .fab {
    padding-right: 35vw !important;
  }

  .fab-left {
    padding-left: 35vw !important;
  }

  .hintModal {
    padding-left: 35vw;
    padding-right: 35vw;
    padding-bottom: 140px;
  }
}

//}

@media screen and (min-width: 600px) {
}

@media screen and (max-width: 370px) {
  .ng-otp-input-wrapper .otp-input {
    height: 35px !important;
    width: 35px !important;
    // margin-right: 15px !important;
  }
}

@media screen and (max-width: 305px) {
  .buttonM {
    font-size: 14px !important;
  }

  .ng-otp-input-wrapper .otp-input {
    height: 30px !important;
    width: 30px !important;
    font-size: 18px !important;
    // margin-right: 15px !important;
  }
}

@media screen and (max-width: 275px) {
  .buttonM {
    font-size: 12px !important;
  }

  .ng-otp-input-wrapper .otp-input {
    height: 25px !important;
    width: 25px !important;
    font-size: 15px !important;
    // margin-right: 15px !important;
  }
}

.challenge-alert {
  .alert-head .alert-title {
    color: var(--ion-color-primary);
  }

  .alert-message {
    color: var(--ion-color-primary);
    text-align: center;
    margin-top: 15px;
  }
}

::-webkit-scrollbar {
  appearance: none;
  width: 4px;
  height: 5px;
  background: var(--ion-color-step-100);
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: var(--ion-color-step-250);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
